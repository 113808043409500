import React from 'react'
import BackgroundImage from "../assets/contact.png"
import SocialMedia from '../components/contact/socialMedia'
import ToContact from '../components/contact/toContact'

const Contact = () => {
  return (
    <div className='flex flex-col w-screen pt-20'>
        <div 
            className='w-screen h-[400px] '
            style={{
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: "cover", // Ensures the image covers the entire container
                backgroundPosition: "center", // Centers the image // Adjust the height based on your needs
                width: "100%",   // Ensures the image takes the full width of the screen
              }}
        >
            <div className='w-full h-full bg-black/60 flex justify-center items-center font-lora-bold'>
                <h1 className='text-4xl text-white'>Contact Us</h1>
            </div>
        </div>
        <div className='w-screen bg-[#F6F6F6] flex flex-col py-24 items-center gap-20'>
            <SocialMedia />
            <ToContact />
        </div>
    </div>
  )
}

export default Contact