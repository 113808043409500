import React from 'react'
import BackgroundImage from "../../assets/iconBlack.png"

const Download = () => {
  return (
    <div className='w-screen bg-[#1C1814] py-24 flex flex-col lg:flex-row gap-20 justify-center items-center  px-10'>
        <div className='flex flex-col gap-10 max-w-[500px] text-center '>
            <h1 className='text-[#A38A00] text-xl font-lora-bold'>Wipoker - The Ultimate Poker Experience!</h1>
            <p className='text-lg font-lora-bold text-white'>Welcome to Wipoker, the ultimate poker app that brings the thrill of real poker games straight to your fingertips. Whether you’re a seasoned poker enthusiast or new to the game, Wipoker provides an exciting platform to sharpen your skills, challenge friends, and compete with players worldwide.</p>
        </div>
        <div className='flex gap-6'>
            <div
            className='border-white border-2'
                style={{
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: "cover", // Ensures the image covers the entire container
                    backgroundPosition: "center", // Centers the image // Adjust the height based on your needs
                    width: "150px",
                    height: "150px",
                    borderRadius: "30px"
                }}
            />
            <div className='flex flex-col gap-3 justify-center'>
                <div className='bg-black rounded-[10px] p-2 border-white border-2 flex items-center justify-center gap-3 min-w-[200px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40px" height="40px">
                        <path fill="#74C0FC" d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z"/>
                    </svg>
                    <div className='flex flex-col '>
                        <p className='text-white font-lora-bold text-xs'>Download on the</p>
                        <h1 className='text-white font-lora-bold text-2xl'>App Store</h1>
                    </div>
                </div>
                <div className='bg-black rounded-[10px] p-2 border-white border-2 flex items-center justify-center gap-3'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="35px" height="35px">
                    <path fill="#63E6BE" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/>
                </svg>
                    <div className='flex flex-col '>
                        <p className='text-white font-lora-bold text-xs'>GET IT ON</p>
                        <h1 className='text-white font-lora-bold text-xl'>Google Play</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Download