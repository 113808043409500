import React from 'react'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'; // Import icons from react-icons
import logo from "../../assets/logo.png"

const Footer = () => {
  return (
    <div className='flex flex-col py-10 gap-5 bg-black justify-center items-center'>
        <p className='text-lg text-white font-serif'>For Bussiness: hamzaiantrin1999@gmail.con </p>
        <div className='flex gap-3'>
            <div className='w-8 h-8 rounded-full bg-white flex justify-center items-center'>
                <FaInstagram />
            </div>
            <div className='w-8 h-8 rounded-full bg-white flex justify-center items-center'>
                <FaFacebookF />
            </div>
            <div className='w-8 h-8 rounded-full bg-white flex justify-center items-center'>
                <FaTwitter />
            </div>
        </div>
        <img src={logo} alt="logo" className='w-20 h-20'/>
        <p className='text-lg text-white font-serif'>© 2024-2025 by WiPoker</p>
    </div>
  )
}

export default Footer