import React from 'react'
import Mockup1 from "../../assets/club1.png"
import Mockup2 from "../../assets/club2.png"

const infos = [
    "- Private club can support up to 2,000 members.",
    "- Independent game records are available for each club.",
    "- Private chat room facilities for members.",
    "- Variety of management tools for the club owner."
]

const Clubs = () => {
  return (
    <div className='flex flex-col lg:flex-row w-screen py-20 justify-center gap-20 items-center bg-black'>
        <div className='flex flex-col gap-10 w-[80%] lg:w-[25%] '>
            <h1 className='text-2xl sm:text-3xl text-[#A38A00] font-lora-bold'>Your Private Poker Club</h1>
            <p className='text-md sm:text-lg text-white font-lora-bold'>With just one click, you can easily set up your own private poker clubs and host games with friends anytime, anywhere.</p>
            <div className='flex flex-col gap-2'>
                {infos.map((item, id) => {
                    return (
                        <p key={id} className='text-sm sm:text-md text-gray-400'>{item}</p>
                    )
                })}
            </div>
        </div>
        <div className='flex flex-col sm:flex-row'>
            <img
                src={Mockup1}
                alt="iPhone mockup"
                className="w-72 h-auto"
            />
            <img
                src={Mockup2}
                alt="iPhone mockup 1"
                className="w-72 h-auto"
            />
        </div>
        
    </div>
  )
}

export default Clubs