import React from 'react'


const About = () => {
  return (
    <div className='pt-32 pb-24 bg-black flex flex-col items-center gap-10'>
        <h1 className='text-[#A38A00] text-3xl font-lora-bold'>What is WiPoker ?</h1>
        <div className='w-screen gap-10 px-10 flex flex-col max-w-[800px]'>
            <p className='text-white text-xl font-lora-bold'><span className='text-[#A38A00]'>Wipoker</span> is a dynamic mobile application designed for poker enthusiasts who want to enjoy a realistic, social poker experience with friends or members of a poker club.</p>
            <p className='text-white text-xl font-lora-bold'>With <span className='text-[#A38A00]'>Wipoker</span>, there's no need for physical cards, chips, or a table. The app provides everything you need to play poker, from tracking game progress to offering a range of features that make organizing and playing poker effortless. Now, you and your friends can dive into exciting poker sessions anytime, anywhere.</p>
            <p className='text-white text-xl font-lora-bold'>Built with user feedback at its core, <span className='text-[#A38A00]'>Wipoker</span> is focused on delivering an authentic poker experience. Over time, we've fine-tuned the gameplay to mirror the thrill of real poker games. Whether you're into Texas Hold'em, Omaha, or other popular formats, Wipoker has a variety of single-table and multi-table tournaments to keep the excitement going.</p>
            <p className='text-white text-xl font-lora-bold'>Playing on <span className='text-[#A38A00]'>Wipoker</span> offers the same intensity and strategy as sitting down at a real table, with the flexibility to play privately with your friends or join public tables to meet new players.</p>
            <p className='text-white text-xl font-lora-bold'>Join the Wipoker community today and experience the next level of virtual poker!</p>
        </div>
    </div>
  )
}

export default About