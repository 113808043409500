import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/global/navbar";
import Footer from "./components/global/footer";
import Home from "./pages/home";
import About from "./pages/about";
import Terms from "./pages/terms";
import Contact from "./pages/contact";


const App = () => {

	return (
        <Router>
    		<div className={"w-screen flex flex-col"}>
        		<Navbar />
        		<Routes>
          			<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/contact" element={<Contact />} />
        		</Routes>
          		<Footer />
      		</div>
    	</Router>
  );
};

export default App;

