import React from 'react'

const myInfos = [
    {
        title: "Customized Games",
        description : "Play the way you would in real life by adjusting your own buy-in range, action time, and game lengths."
    },
    {
        title: "Games with friends",
        description : "Host private games with friends and club members, or play with others online in our public games."
    },
    {
        title: "Realistic Play",
        description : "Throw in your chips, fold your cards, or check your turn using our customized gestures that mimic real game play."
    },
]

const Infos = () => {

  return (
    <div className='w-screen bg-[#DBDBDB] flex flex-wrap gap-20 sm:gap-10 justify-center items-center py-20'>
        {myInfos.map((item, id) => {
            return (
                <div key={id} className='flex flex-col gap-4 items-center '>
                    <h1 className='text-black text-2xl font-lora-bold'>{item.title}</h1>
                    <p className='text-gray-500 text-lg w-[400px] text-center'>{item.description}</p>
                </div>
            )
        })}

    </div>
  )
}

export default Infos