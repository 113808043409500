import React from 'react'
import Mockup from "../../../src/assets/friends.png"

const FriendsSection = () => {
  return (
    <div className={'w-screen  bg-[#098167] py-24 flex flex-col-reverse sm:flex-row gap-20 justify-center items-center'}>
        <img
            src={Mockup}
          alt="iPhone mockup"
          className="w-72 h-auto"
        />
        <div className='flex flex-col gap-10 w-[80%] sm:w-auto'>
            <h1 className='text-3xl font-lora-bold text-[#A38A00] text-center sm:text-start'>Poker with Friends</h1>
            <p className='text-white text-lg  sm:w-[400px] text-center sm:text-start'> ​​Bring friends into your poker game wherever they are, even Jupiter.</p>
        </div>
    </div>
  )
}

export default FriendsSection