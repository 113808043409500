import React from 'react'

const infos = [
    {
        comment: '"It’s great for those times when you don’t have a deck of cards or poker chips lying around."',
        media: "--- Facebook"
    },
    {
        comment : '"It’s great for those times when you don’t have a deck of cards or poker chips lying around."',
        media: "--- Twitter"
    },
    {
        comment: '”It’s a really great update on poker playing experience, so download Pokerrrr and try something new for POKER NIGHT.“',
        media: "--- Google"
    }
]

const Reviews = () => {
  return (
    <div className='flex flex-col gap-10 py-20 items-center justify-center bg-[#DBDBDB]'>
        <h1 className='text-xl sm:text-3xl font-lora-bold text-[#A38A00]'>What people said about Pokerrrr 2</h1>
        {infos.map((item, id) => {
            return (
                <div className='flex flex-col w-[80%] sm:w-[40%]'>
                    <p className='text-xl font-lora-bold text-gray-500 ' >{item.comment}</p>
                    <p className='text-xl font-lora-bold text-black text-right'>{item.media}</p>
                </div>
            )
        })}
    </div>
  )
}

export default Reviews