import React from 'react'
import Mockup from "../../../src/assets/iphone.png"

const coinsItems = [
    "- Texas Hold'em",
    "- Short Deck Poker",
    "- OFC",
    "- 7 Card Stud",
    "​- Blackjack",
    "​- Rummy"
]

const cardsItems = [
    "- Omaha",
    "- Omaha Hi-Lo",
    "- 5 Card Omaha"
]

const prizeItems = [
    "- Sit n' Go (SNG)",
    "- Multi-Table Tournament (MTT)"
]

const SocialMedia = () => {


  return (
    <div className='w-screen bg-[#212121] py-24 flex flex-col-reverse sm:flex-row gap-20 justify-center items-center sm:items-start'>
         <img
            src={Mockup}
          alt="iPhone mockup"
          className="w-72 h-auto"
        />
        <div className='flex flex-col gap-10 items-center'>
            <h1 className='text-[#A38A00] text-xl sm:text-3xl font-lora-bold'>Features a Variety of WiPoker Games</h1>
            <div className='flex flex-col gap-10'>
                <div className='flex gap-10 items-center'>
                    <img src="https://static.wixstatic.com/media/9dfa3b_868b1907d8c846a7a664af198ecc0c72~mv2.png/v1/fill/w_166,h_166,al_c,lg_1,q_85,enc_auto/9dfa3b_868b1907d8c846a7a664af198ecc0c72~mv2.png" alt="coins" className='w-16 sm:w-24 h-16 sm:h-24'/>
                    <div className='flex flex-col'>
                        {coinsItems.map((item, id) => {
                            return(
                                <p className='text-white font-lora-bold text-sm sm:text-lg'>{item}</p>
                            )
                        })}
                    </div>
                </div>
                <div className='flex gap-10 items-center'>
                    <img src="https://static.wixstatic.com/media/9dfa3b_f337c22223de4c46a65207840117e94c~mv2.png/v1/fill/w_166,h_166,al_c,lg_1,q_85,enc_auto/9dfa3b_f337c22223de4c46a65207840117e94c~mv2.png" alt="coins" className='w-16 sm:w-24 h-16 sm:h-24'/>
                    <div className='flex flex-col'>
                        {cardsItems.map((item, id) => {
                            return(
                                <p className='text-white font-lora-bold text-sm sm:text-lg'>{item}</p>
                            )
                        })}
                    </div>
                </div>
                <div className='flex gap-10 items-center'>
                    <img src="https://static.wixstatic.com/media/9dfa3b_d83d630267524d11bde1e5464416106c~mv2.png/v1/fill/w_166,h_162,al_c,lg_1,q_85,enc_auto/9dfa3b_d83d630267524d11bde1e5464416106c~mv2.png" alt="coins" className='w-16 sm:w-24 h-16 sm:h-24'/>
                    <div className='flex flex-col'>
                        {prizeItems.map((item, id) => {
                            return(
                                <p className='text-white font-lora-bold text-sm sm:text-lg'>{item}</p>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default SocialMedia