import React from 'react'
import { SocialIcon } from 'react-social-icons';


const data = [
    { name: "Instagram", url: "https://instagram.com", link: "instagram.com/WiPoker" },
    { name: "Facebook", url: "https://facebook.com", link: "facebook.com/WiPoker" },
    { name: "Twitter", url: "https://twitter.com", link: "twitter.com/WiPoker" }
]

const SocialMedia = () => {
  return (
    <div className='w-[80%] bg-white  rounded-md flex flex-wrap justify-between p-10 gap-10 items-center max-w-[1200px]'>
        {data.map((item, id) => {
            return (
                <div key={id} className='flex flex-col gap-5'>
                	<div className='flex gap-3 items-center'>
                    	<SocialIcon url={item.url} />
						<h1 className='font-lora-bold'>{item.name}</h1>
                	</div>
					<h1 className='font-lora-bold underline underline-offset-1 text-gray-600'>{item.link}</h1>
                </div>
            )
        })}
    </div>
  )
}

export default SocialMedia