import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png"  // Adjust path to your logo file

const Navbar = ( ) => {
  const items = [
    { name: "Home", path: "/" },
    { name: "Terms", path: "/terms" },
    { name: "About", path: "/about" },
  ];

  return (
    <div className={"w-screen bg-black flex justify-between items-center h-20 fixed px-2 sm:px-10 z-50"}>
      	<Link to={"/"} >
	  		<img src={logo} alt="logo" className="w-12 sm:w-20 h-12 sm:h-20" />
		</Link>
      <div className="flex space-x-5 sm:space-x-12">
        {items.map((item, index) => (
          <Link 
            key={index} 
            to={item.path} 
            className="text-white text-md sm:text-xl font-lora-bold hover:text-gray-400 transition-colors duration-200"
          >
            {item.name}
          </Link>
        ))}
      </div>
      <Link
        className="text-white text-sm sm:text-xl px-2 sm:px-4 py-2 rounded-md hover:bg-opacity-80 transition-all"
        style={{ backgroundColor: '#A38A00' }}
		to="/contact"
      >
        Contact Us
      </Link>
    </div>
  );
};

export default Navbar;
