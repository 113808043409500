import React from 'react'
import SocialMedia from '../components/home/socialMedia'
import FriendsSection from '../components/home/friendsSection'
import Infos from '../components/home/infos'
import Clubs from '../components/home/clubs'
import Reviews from '../components/home/reviews'
import wallpaper from "../assets/wallpaper.png"
import Download from '../components/home/download'

const Home = () => {
  return (
    <div>
        <img
            src={wallpaper}
            alt="wallpaper"
            className="w-screen md:h-[600px] h-[400px] pt-20"
        />
        <Download />
        <SocialMedia />
        <FriendsSection />
        <Infos />
        <Clubs />
        <Reviews />
    </div>
  )
}

export default Home