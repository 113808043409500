import React from 'react'
import BackgroundImage from "../assets/termsBackground.png"

const Terms = () => {
  return (
    <div 
        className='pt-32 pb-24 bg-black flex flex-col items-center gap-10'
        style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: "cover", // Ensures the image covers the entire container
            backgroundPosition: "center", // Centers the image // Adjust the height based on your needs
            width: "100%",   // Ensures the image takes the full width of the screen
          }}
    >
        <h1 className='text-[#A38A00] text-2xl font-lora-bold'>Wipoker Terms and Conditions</h1>
        <div className='w-screen gap-10 px-10 flex flex-col max-w-[800px]'>
            <h1 className="text-2xl font-bold">Terms of Use</h1>
            <p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the Wipoker mobile application. Mondraw Limited ("us", "we", or "our") operates the Wipoker mobile application, website, social media pages, and related services (collectively, the "Service").</p>
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. If you disagree with any part of the terms, you may not access the Service.</p>
            <p>This software is for entertainment purposes only. Using this software to gamble for money or anything of value is done at your own risk. By registering for an Account or otherwise using the Service, you affirm that you (1) are 18 years or older, or 13 years or older and have your parent’s or legal guardian's consent to use the Service; (2) are capable of entering into a legally binding agreement; and (3) will abide by these Terms at all times.</p>

            <h2 className="text-xl font-semibold">1. Account Registration and Security</h2>
            <p>When creating an account on the Wipoker platform, you agree to provide accurate, current, and complete information as prompted during the registration process. You are responsible for maintaining the confidentiality of your account and password, as well as all activities that occur under your account.</p>
            <p>You agree to notify us immediately of any unauthorized use of your account or any other breach of security. We will not be liable for any loss or damage arising from your failure to comply with this security obligation.</p>

            <h2 className="text-xl font-semibold">2. Prohibited Conduct</h2>
            <p>While using the Service, you agree not to:</p>
            <ul className="list-disc list-inside">
                <li>Violate any laws or regulations;</li>
                <li>Use the Service for any illegal purposes, including gambling with real money or value;</li>
                <li>Transmit any content that is unlawful, offensive, harmful, or inappropriate;</li>
                <li>Impersonate any individual or entity, including our company, or falsely state or misrepresent your affiliation with an individual or entity;</li>
                <li>Interfere with or disrupt the Service, or attempt to gain unauthorized access to any systems or networks connected to the Service;</li>
                <li>Use any automated system, including but not limited to robots, scrapers, or offline readers, to access the Service in a manner that sends more request messages than a human can reasonably produce in the same period of time.</li>
            </ul>
            <p>Violation of these prohibitions may result in termination of your access to the Service.</p>

            <h2 className="text-xl font-semibold">3. Virtual Goods and In-App Purchases</h2>
            <p>The Service may offer certain virtual goods and in-app purchases (collectively, “Virtual Goods”). These Virtual Goods have no real-world monetary value and cannot be exchanged or redeemed for real money, goods, or services. All Virtual Goods are licensed to you on a limited, non-transferable, non-exclusive, and revocable basis, and are subject to these Terms.</p>
            <p>Purchases of Virtual Goods are final and non-refundable unless required by law. We reserve the right to modify, manage, control, or eliminate Virtual Goods at our sole discretion without notice or liability.</p>

            <h2 className="text-xl font-semibold">4. Termination</h2>
            <p>We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including but not limited to a breach of these Terms.</p>
            <p>Upon termination, your right to use the Service will cease immediately. If you wish to terminate your account, you may simply discontinue using the Service. All provisions of the Terms which by their nature should survive termination, shall survive, including but not limited to ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

            <h2 className="text-xl font-semibold">5. Intellectual Property</h2>
            <p>The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Mondraw Limited or its licensors and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
            <p>You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the Service and its content for personal, non-commercial purposes. Any use of the Service or its content for commercial purposes or public display without prior written consent from Mondraw Limited is strictly prohibited.</p>

            <h2 className="text-xl font-semibold">6. Limitation of Liability</h2>
            <p>To the fullest extent permitted by applicable law, in no event shall Mondraw Limited, its affiliates, or their respective directors, officers, employees, or agents, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of, or inability to access or use, the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; or (iv) any bugs, viruses, Trojan horses, or the like that may be transmitted to or through the Service by any third party.</p>

            <h2 className="text-xl font-semibold">7. Disclaimer of Warranties</h2>
            <p>The Service is provided on an “AS IS” and “AS AVAILABLE” basis, without any warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
            <p>Mondraw Limited does not warrant that (i) the Service will be uninterrupted, error-free, or secure; (ii) any defects or errors will be corrected; or (iii) the results that may be obtained from the use of the Service will be accurate or reliable.</p>

            <h2 className="text-xl font-semibold">8. Changes to the Terms</h2>
            <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.</p>
            <p>By continuing to access or use the Service after any revisions become effective, you agree to be bound by the updated terms. If you do not agree to the new terms, you must stop using the Service.</p>

            <h2 className="text-xl font-semibold">9. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at <span className='text-[#A38A00]'>hamzaiantrin1999@gmail.com.</span></p>
        </div>
    </div>
  )
}

export default Terms